import "./Contact.css"
import React from "react"
import whatsapp from "../images/whatsapp.svg"
import mobile from "../images/device-mobile.svg"
import mail from "../images/mail.svg"
import office from "../images/map.svg"

export default function Contact({ windowSize, forwardedRef }) {
    return (
        <div className="contact-section-container" ref={forwardedRef}>
            <div className="text-container">
                <p className="sec3-header1">İletişim</p>
                <p className="sec3-header2"></p>
            </div>

            <div className="contact-info-c">
                <div
                    className="contact-info-container"
                    // href="https://wa.me/905075373074"
                    // target="_blank"
                >
                    {/* <div className="contact-info-container"> */}
                    <div className="contact-info-image-container">
                        <img
                            className="contact-info-image"
                            src={office}
                            alt=""
                        />
                    </div>
                    <div>
                        <p className="header3">Ofis</p>
                        <p className="info-text2">
                            Çeştepe Mah. Muğla Blv. No:99 Efeler/Aydın
                        </p>
                        {/* <p className="header3">Ofis</p>
                        <p className="info-text2 ">
                            Zafer Mahallesi, Üniversite Varyantı Caddesi, ADÜ
                            Teknokent, No:64 Efeler / AYDIN
                        </p> */}
                    </div>
                    {/* </div> */}
                </div>

                <a className="contact-info-container" href="tel:507-537-30-74">
                    <div className="contact-info-image-container">
                        <img
                            className="contact-info-image"
                            src={mobile}
                            alt=""
                        />
                    </div>
                    <div>
                        <p className="header3">Telefon Numarası</p>
                        <p className="info-text2"> 0507 537 30 74</p>
                    </div>
                </a>
                <a
                    className="contact-info-container"
                    href="mailto:info@calculusmuhendislik.com"
                >
                    {/* <div className="contact-info-container"> */}
                    <div className="contact-info-image-container">
                        <img className="contact-info-image" src={mail} alt="" />
                    </div>
                    <div>
                        <p className="header3">Mail Adresi</p>

                        {windowSize.innerWidth < 375 ? (
                            <p className="info-text2">
                                info@calculus muhendislik.com
                            </p>
                        ) : (
                            <p className="info-text2">
                                info@calculusmuhendislik.com
                            </p>
                        )}
                    </div>
                    {/* </div> */}
                </a>
            </div>
            <div className="contact-info-container-map">
                {/* <div className="contact-info-container1">
                    <div className="contact-info-image-container">
                        <img
                            className="contact-info-image"
                            src={office}
                            alt=""
                        />
                    </div>
                    <div className="contact-info-text">
                        <p className="header3">Ofis</p>
                        <p className="info-text2 ">
                            Zafer Mahallesi, Üniversite Varyantı Caddesi, ADÜ
                            Teknokent, No:64 Efeler / AYDIN
                        </p>
                    </div>
                </div> */}
                {/* <iframe
                    src=""
                    width="600"
                    height="450"
                    style="border:0;"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                ></iframe> */}
                {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d263.4643890335321!2d27.845791102721897!3d37.83896192380132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b92bdd4ecb3ce9%3A0xe336359319d4d2b4!2sR%C3%B6nesansplaza!5e0!3m2!1str!2str!4v1679744774778!5m2!1str!2str"
                    width="600"
                    height="450"
                    style="border:0;"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                ></iframe> */}
                <iframe
                    src="https://maps.google.com/maps?q=37.802279, 27.839767&z=15&output=embed"
                    style={
                        windowSize.innerWidth < 1355
                            ? { border: 0, width: "auto", height: 200 }
                            : { border: 0, width: "100%", height: "100%" }
                    }
                    className="gmap"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
    )
}
