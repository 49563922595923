import "./RefCard.css"
import React from "react"

export default function RefCard({ logo, header, info, number }) {
    return (
        <div className="refCard-container">
            {/* <div className="refCard-number">{number}</div> */}
            <div className="refCard-bottom-container">
                <img src={logo} alt={header} className="refCard-image" />
                <div className="refCard-bottom-container-right">
                    <p className="refCard-header">{header}</p>
                    {/* <p className="refCard-info">{info}</p> */}
                </div>
            </div>
        </div>
    )
}
