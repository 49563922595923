import "./Section2.css"
import React from "react"

export default function Section2({ forwardedRef }) {
    return (
        <div ref={forwardedRef} className="sec2-container">
            <div className="sec2-leftcontainer">
                <p className="sec2-header2">Biz Kimiz?</p>
                <p className="sec2-header1">Hakkımızda</p>
                <p className="sec2-text1">
                    Misyonumuz, sürekli gelişme anlayışıyla ve bilgi
                    birikimimizle, ürün ve hizmetlerimizi gelişen toplumun
                    gelişen beklentilerine uygun hale getirmek.
                </p>
                <p className="sec2-text2">
                    Biz, gelişen dünyada enerji ve teknolojiye verdiğimiz önemle
                    birlikte herbiri kendi alanında uzman, güçlü teknik
                    kadromuzla entegre olduğumuz enerji ve üretim sektörlerinde
                    endüstriyel otomasyon, yazılım, elektrik taahhüt işleri,
                    projelendirme, mekanik tasarım ve imalat alanlarında hizmet
                    vermekteyiz.
                </p>
                <p className="sec2-text2">
                    Vizyonumuz, profesyonel bir bakış açısıyla, teknik çözüm
                    sunma becerileriyle, paydaşları tarafından küresel ölçekte
                    en çok tercih edilen, enerji ve üretim sektörlerinin
                    yarınına yön veren önemli aktörlerden biri olmak.
                </p>
            </div>

            <div className="sec2-rightcontainer">
                <div className="sec2-listcontainer">
                    <p className="sec2-listheader">Değerlerimiz</p>
                    <ul className="sec2-list">
                        <li className="sec2-listitem">Dürüstlük</li>
                        <li className="sec2-listitem">Şeffaflık</li>
                        <li className="sec2-listitem">Çözüm Odaklı Olmak</li>
                        <li className="sec2-listitem">
                            Hızlı ve Etkin Kararlar Alıp Uygulamak
                        </li>
                        <li className="sec2-listitem">
                            Sektörel İhtiyaçlara Cevap Vermek
                        </li>
                    </ul>
                </div>
                <div className="sec2-social-container">
                    <p className="sec2-socialheader">Sosyal Medya</p>

                    <a
                        href="https://www.facebook.com/turkertan"
                        target="_blank"
                    >
                        <i className="fa-brands fa-facebook-f fa-lg fa-5x sec2-social"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/ertann_turk/"
                        target="_blank"
                    >
                        <i className="fa-brands fa-instagram fa-xl sec2-social"></i>
                    </a>

                    <a
                        href="https://www.linkedin.com/in/ertanturk/"
                        target="_blank"
                    >
                        <i className="fa-brands fa-linkedin fa-xl  sec2-social"></i>
                    </a>
                </div>
            </div>
        </div>
    )
}
