export const refsJSON = [
    {
        name: "KİPAŞ HOLDİNG ",
        image: require("../images/refImage/01.png"),
    },
    {
        name: "KARİZMA ENERJİ İNŞAAT",
        image: require("../images/refImage/02.png"),
    },
    {
        name: "MAREN MARAŞ",
        image: require("../images/refImage/03.png"),
    },
    {
        name: "LİMAK HOLDİNG",
        image: require("../images/refImage/04.png"),
    },
    {
        name: "TÜRKERLER HOLDİNG",
        image: require("../images/refImage/05.png"),
    },
    {
        name: "ENERJİSA ENERJİ",
        image: require("../images/refImage/06.png"),
    },
    {
        name: "GREEN CHEMİCALS",
        image: require("../images/refImage/07.png"),
    },
    {
        name: "ÖZMEN HOLDİNG ENERJİ",
        image: require("../images/refImage/08.png"),
    },
    {
        name: "MENDERES GEOTHERMAL",
        image: require("../images/refImage/09.png"),
    },
    {
        name: "EGESİM ENERJİ",
        image: require("../images/refImage/10.png"),
    },
    {
        name: "EFEMAK PETROL",
        image: require("../images/refImage/11.png"),
    },
    {
        name: "FSE ENERJİ MÜHENDİSLİK ",
        image: require("../images/refImage/12.png"),
    },
    {
        name: "PEKPAN İNŞAAT",
        image: require("../images/refImage/13.png"),
    },
]
