import "./GallerySection.css"
import React, { useState } from "react"
import GalleryImage from "./ui/GalleryImage"
import fizibilite from "../images/fizibilite.png"
import { gallery } from "../data/gallery"

export default function GallerySection({ forwardedRef }) {
    const [showMore, setShowMore] = useState(false)

    function showMoreHandler() {
        setShowMore((prevState) => !prevState)
    }

    return (
        <div className="gallery-section" ref={forwardedRef}>
            <div className="text-container">
                <p className="sec3-header1">Galeri</p>
                <p className="sec3-header2"></p>
                <p className="info-text">
                    Endüstriyel otomasyon, elektrik ve mekanik alanlarında
                    tecrübeli kadromuzla ve mühendislik çözümlerimizle
                    yanınızdayız.
                </p>
            </div>

            <div className="images-container">
                {gallery.map((galleryOb) => {
                    return <GalleryImage galleryData={galleryOb} />
                })}
            </div>

            {/* <div className={showMore ? "sec4-showmore " : "sec4-showless"}>
                <div className="gallery-showmore-container">
                    <GalleryImage
                        img={fizibilite}
                        header={"Fizibilite Çalışması"}
                        info={"Modo mutat"}
                    />
                    <GalleryImage
                        img={fizibilite}
                        header={"Fizibilite Çalışması"}
                        info={"Modo mutat"}
                    />
                    <GalleryImage
                        img={fizibilite}
                        header={"Fizibilite Çalışması"}
                        info={"Modo mutat"}
                    />{" "}
                    <GalleryImage
                        img={fizibilite}
                        header={"Fizibilite Çalışması"}
                        info={"Modo mutat"}
                    />
                    <GalleryImage
                        img={fizibilite}
                        header={"Fizibilite Çalışması0"}
                        info={"Modo mutat"}
                    />
                    <GalleryImage
                        img={fizibilite}
                        header={"Fizibilite Çalışması0"}
                        info={"Modo mutat"}
                    />
                </div>
            </div> */}
            {/* <div>
                <p className="sec4-showmore-button" onClick={showMoreHandler}>
                    {showMore ? "Daha az göster" : "Daha fazla göster"}
                </p>
            </div> */}
        </div>
    )
}
