import "./Section4.css"
import React, { useState } from "react"
import RefCard from "./ui/RefCard"
import turkerler from "../images/turkerler.png"
import { refsJSON } from "../data/ref"

export default function Section4({ forwardedRef }) {
    const [showMore, setShowMore] = useState(false)

    function showMoreHandler() {
        setShowMore((prevState) => !prevState)
    }

    // const renderItems =
    return (
        <div className="section4-container" ref={forwardedRef}>
            <div className="text-container">
                <p className="sec3-header1">Referanslarımız</p>
                <p className="sec3-header2"></p>
            </div>

            <div className="ref-card-container">
                {refsJSON.map((ref, i) => {
                    while (i < 6) {
                        return <RefCard header={ref.name} logo={ref.image} />
                    }
                })}
                {/* <RefCard
                    number={"01"}
                    info={"Persecuti, porro animal no cum ludus1"}
                    header={"Türkerler"}
                    logo={turkerler}
                /> */}
                {/* <RefCard
                    number={"01"}
                    info={"Persecuti, porro animal no cum ludus2"}
                    header={"Türkerler"}
                    logo={turkerler}
                />
                <RefCard
                    number={"01"}
                    info={"Persecuti, porro animal no cum ludus"}
                    header={"Türkerler"}
                    logo={turkerler}
                />
                <RefCard
                    number={"01"}
                    info={"Persecuti, porro animal no cum ludus"}
                    header={"Türkerler"}
                    logo={turkerler}
                />
                <RefCard
                    number={"01"}
                    info={"Persecuti, porro animal no cum ludus"}
                    header={"Türkerler"}
                    logo={turkerler}
                />{" "}
                <RefCard
                    number={"01"}
                    info={"Persecuti, porro animal no cum ludus"}
                    header={"Türkerler"}
                    logo={turkerler}
                /> */}
            </div>
            <div
                className={
                    showMore
                        ? "sec4-showmore ref-card-container"
                        : "sec4-showless"
                }
            >
                {refsJSON.map((ref, i) => {
                    while (i > 5) {
                        return <RefCard header={ref.name} logo={ref.image} />
                    }
                })}
            </div>
            <p className="sec4-showmore-button" onClick={showMoreHandler}>
                {showMore ? "Daha az göster" : "Daha fazla göster"}
            </p>
        </div>
    )
}
