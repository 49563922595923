import "./Header.css"
import React, { useState } from "react"
import logo from "./../images/calc_logo.svg"
import mobile from "../images/device-mobile.svg"

// import logo from "./../images/calculuslogo2.png"

export default function Header({ windowSize, scrollDown, forwardedRefs }) {
    const [isHamburgerMenuActive, setIsHamburgerMenuActive] = useState(false)
    let headerView

    function hamburgerMenuHandler() {
        setIsHamburgerMenuActive((prevstate) => !prevstate)
    }

    function handleScrollDown(ref) {
        scrollDown(ref)
        if (
            ref.current.className === "contact-section-container" ||
            ref.current.className === "slider-container"
        ) {
            setIsHamburgerMenuActive(false)
        } else {
            setIsHamburgerMenuActive((prevstate) => !prevstate)
        }
    }

    if (windowSize.innerWidth < 992) {
        headerView = (
            <>
                <div className="headerContainerOutside sticky">
                    <div className="headerContainer">
                        <img
                            className="logo"
                            src={logo}
                            alt="Calculus Muhendislik"
                            onClick={() =>
                                handleScrollDown(forwardedRefs.homeSection)
                            }
                        />

                        <ul className="contact-container">
                            <div>
                                <li
                                    onClick={() =>
                                        handleScrollDown(
                                            forwardedRefs.contactSection
                                        )
                                    }
                                >
                                    {" "}
                                    İletişim
                                </li>
                            </div>
                        </ul>
                        <div className="icon-container">
                            <i
                                onClick={hamburgerMenuHandler}
                                className={
                                    isHamburgerMenuActive
                                        ? "fa-solid fa-bars fa-lg hamburger-menu hamburger-menu-active"
                                        : "fa-solid fa-bars fa-lg hamburger-menu "
                                }
                            ></i>
                        </div>
                    </div>
                    <div
                        className={
                            isHamburgerMenuActive ? "links" : "links-inactive"
                        }
                    >
                        <ul className="nav-items">
                            <li
                                className="nav-item-mobile link"
                                onClick={() =>
                                    handleScrollDown(forwardedRefs.aboutSection)
                                }
                            >
                                Hakkımızda
                            </li>
                            <li
                                className="nav-item-mobile link"
                                onClick={() =>
                                    handleScrollDown(
                                        forwardedRefs.serviceSection
                                    )
                                }
                            >
                                Hizmetler
                            </li>
                            <li
                                className="nav-item-mobile link"
                                onClick={() =>
                                    handleScrollDown(
                                        forwardedRefs.referenceSection
                                    )
                                }
                            >
                                Referanslar
                            </li>
                            <li
                                className="nav-item-mobile link"
                                onClick={() =>
                                    handleScrollDown(
                                        forwardedRefs.gallerySection
                                    )
                                }
                            >
                                Galeri
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        )
    } else {
        headerView = (
            <>
                <>
                    <div className="headerContainerOutside headerContainer sticky">
                        <img
                            className="logo"
                            src={logo}
                            alt="Calculus Muhendislik"
                            onClick={() =>
                                handleScrollDown(forwardedRefs.homeSection)
                            }
                        />
                        <a className="phone-number" href="tel:507-537-30-74">
                            0507 537 30 74
                        </a>
                        <ul className="nav-items">
                            <li
                                className="nav-item-mobile link"
                                onClick={() =>
                                    handleScrollDown(forwardedRefs.aboutSection)
                                }
                            >
                                Hakkımızda
                            </li>
                            <li
                                className="nav-item-mobile link"
                                onClick={() =>
                                    handleScrollDown(
                                        forwardedRefs.serviceSection
                                    )
                                }
                            >
                                Hizmetler
                            </li>
                            <li
                                className="nav-item-mobile link"
                                onClick={() =>
                                    handleScrollDown(
                                        forwardedRefs.referenceSection
                                    )
                                }
                            >
                                Referanslar
                            </li>

                            <li
                                className="nav-item-mobile link"
                                onClick={() =>
                                    handleScrollDown(
                                        forwardedRefs.gallerySection
                                    )
                                }
                            >
                                Galeri
                            </li>
                        </ul>
                        <ul className="contact-container">
                            <div
                                onClick={() =>
                                    handleScrollDown(
                                        forwardedRefs.contactSection
                                    )
                                }
                            >
                                <li>İletişim</li>
                            </div>
                        </ul>
                        {/* <div className="icon-container">
                        <i
                            onClick={hamburgerMenuHandler}
                            className={
                                isHamburgerMenuActive
                                    ? "fa-solid fa-bars fa-lg hamburger-menu hamburger-menu-active"
                                    : "fa-solid fa-bars fa-lg hamburger-menu "
                            }
                        ></i>
                    </div> */}
                    </div>
                    {/* <div
                    className={
                        isHamburgerMenuActive ? "links" : "links-inactive"
                    }
                >
                    <ul className="nav-items">
                        <a className="link" href="#">
                            <li className="nav-item-mobile">Hakkımızda</li>
                        </a>
                        <a className="link" href="#">
                            <li className="nav-item-mobile">Hizmetler</li>
                        </a>
                        <a className="link" href="#">
                            <li className="nav-item-mobile">Referanslar</li>
                        </a>
                        <a className="link" href="#">
                            <li className="nav-item-mobile">Galeri</li>
                        </a>
                    </ul>
                </div> */}
                </>
            </>
        )
    }

    return headerView
}
