import { useEffect, useState, useRef } from "react"
import "./App.css"

import CommentSection from "./components/CommentSection"
import Contact from "./components/Contact"
import Footer from "./components/Footer"
import GallerySection from "./components/GallerySection"
import Header from "./components/Header"
// import Section1 from "./components/Section1"
import Section2 from "./components/Section2"
import Section3 from "./components/Section3"
import Section4 from "./components/Section4"
import ImageSlider from "./components/ui/ImageSlider"
import { slides } from "./data/slides"

function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
}

function App() {
    const [windowSize, setWindowSize] = useState(getWindowSize())
    const aboutSection = useRef(null)
    const serviceSection = useRef(null)
    const referenceSection = useRef(null)
    const gallerySection = useRef(null)
    const contactSection = useRef(null)
    const homeSection = useRef(null)

    const refs = {
        aboutSection: aboutSection,
        serviceSection: serviceSection,
        referenceSection: referenceSection,
        gallerySection: gallerySection,
        contactSection: contactSection,
        homeSection: homeSection,
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize())
        }

        window.addEventListener("resize", handleWindowResize)

        return () => {
            window.removeEventListener("resize", handleWindowResize)
        }
    }, [])

    function scrollDown(ref) {
        window.scrollTo({
            top: ref.current.offsetTop - 84,
            behavior: "smooth",
        })
    }

    return (
        <div className="App">
            <Header
                windowSize={windowSize}
                scrollDown={scrollDown}
                forwardedRefs={refs}
            />
            <ImageSlider forwardedRef={homeSection} slides={slides} />
            <Section2 forwardedRef={aboutSection} />
            <Section3 forwardedRef={serviceSection} windowSize={windowSize} />
            <Section4 forwardedRef={referenceSection} />
            <CommentSection />
            <GallerySection forwardedRef={gallerySection} />
            <Contact forwardedRef={contactSection} windowSize={windowSize} />
            <Footer />
            <a
                href="https://wa.me/905075373074"
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fa-brands fa-whatsapp whatsapp-icon"></i>
            </a>
        </div>
    )
}

export default App
