import "./Section3.css"
import React from "react"
import solarpanel from "./../images/solarpanel.svg"
import mechanic from "./../images/mechanic.svg"
import robot from "./../images/robot.svg"
import electric from "./../images/electric.svg"

export default function Section3({ windowSize, forwardedRef }) {
    return (
        <div className="sec3-container" ref={forwardedRef}>
            <div className="text-container">
                <p className="sec3-header1">Hizmetlerimiz</p>
                <p className="sec3-header2"></p>
                <p className="info-text">
                    Endüstriyel otomasyon, elektrik ve mekanik alanlarinda
                    tecrübeli kadromuzla ve mühendislik çözümlerimizle
                    yanınızdayız.
                </p>
            </div>
            <div className="sec3-servicescontainer">
                <div className="sec3-service1">
                    <div className="sec3-service1-imagecontainer">
                        <p className="imagecontainer-number1">01</p>
                        <img
                            className="sec-3-services-image-panel"
                            src={solarpanel}
                            alt="solarpanel"
                        />
                    </div>
                    <div className="sec3-service-list1">
                        <p className="sec3-service1-list-header">
                            Güneş Enerji Sistemleri
                        </p>
                        {windowSize.innerWidth < 992 ? (
                            <ul className="sec3-list1">
                                Organize sanayi bölgeleri, depolama alanları,
                                tarım ve hayvancılık tesisleri, alışveriş
                                merkezleri, oteller, hastaneler ve akaryakıt
                                istasyonları gibi oldukça geniş bir uygulama
                                alanında Güneş Enerji Sistemleri projelendirme
                                ve taahhüt işlemlerini gerçekleştirmekteyiz.
                            </ul>
                        ) : (
                            <p className="sec3-service1-text">
                                Organize sanayi bölgeleri, depolama alanları,
                                tarım ve hayvancılık tesisleri, alışveriş
                                merkezleri, oteller, hastaneler ve akaryakıt
                                istasyonları gibi oldukça geniş bir uygulama
                                alanında Güneş Enerji Sistemleri projelendirme
                                ve taahhüt işlemlerini gerçekleştirmekteyiz.
                            </p>
                        )}
                    </div>
                </div>
                <div className="bottom-services">
                    <div className="sec3-service2">
                        <div className="sec3-service2-imagecontainer">
                            <p className="imagecontainer-number2">02</p>
                            <img
                                className="sec-3-services-image-mech"
                                src={mechanic}
                                alt="mechanic"
                            />
                        </div>

                        <div className="sec3-service-list">
                            <p className="sec3-service-list-header">Mekanik</p>
                            <ul className="sec3-list">
                                <li>Tasarım</li>
                                <li>Simülasyon ve İmalat</li>
                                <li>2-D ve 3-D Teknik Çizim</li>
                                <li>Proses Dizaynı</li>
                                <li>Borulama Dizaynı ve Hesaplamaları</li>
                                <li>Pompa Seçim ve Hesapları</li>
                                <li>Paslanmaz ve Çelik İşleri</li>
                                <li>Çelik Konstrüksiyon</li>
                                <li>Pnömatik Sistem Dizaynı</li>
                                <li>Hidrolik Sistem Dizaynı</li>
                                <li>AR-GE Çalışmaları</li>
                                <li>Owner's Engineering</li>
                            </ul>
                        </div>
                    </div>
                    <div className="sec3-service2">
                        <div className="sec3-service2-imagecontainer">
                            <p className="imagecontainer-number2">03</p>
                            <img
                                className="sec-3-services-image-robot"
                                src={robot}
                                alt="robot"
                            />
                        </div>

                        <div className="sec3-service-list">
                            <p className="sec3-service-list-header">
                                Otomasyon ve Enstrümantasyon
                            </p>
                            <ul className="sec3-list">
                                <li>PLC ve HMI Programlama</li>
                                <li>Scada Yazılımları</li>
                                <li>Enstrümantasyon</li>
                                <li>Kontrol Vanaları</li>
                                <li>Ölçüm Sistemleri</li>
                                <li>Haberleşeme Sistemleri</li>
                                <li>Uzaktan Takip ve Kontrol Sistemleri</li>
                                <li>Makinalar Arası İletişim</li>
                                <li>Servo Kontrol Sistemleri</li>
                                <li>AR-GE Çalışmaları</li>
                                <li>Owner's Engineering</li>
                            </ul>
                        </div>
                    </div>
                    <div className="sec3-service2">
                        <div className="sec3-service2-imagecontainer">
                            <p className="imagecontainer-number2">04</p>
                            <img
                                className="sec-3-services-image-electric"
                                src={electric}
                                alt="electric"
                            />
                        </div>

                        <div className="sec3-service-list">
                            <p className="sec3-service-list-header">Elektrik</p>
                            <ul className="sec3-list">
                                <li>Elektrik Proje Çizimi</li>
                                <li>Motor ve VFD Seçimleri</li>
                                <li>AG-OG-YG Projelendirme</li>
                                <li>Saha Devreye Alama İşleri</li>
                                <li>MCC Pano İmalatları</li>
                                <li>Tek Hat Şemaları</li>
                                <li>Kablo Hesapları</li>
                                <li>Güç Hesapları</li>
                                <li>Enerji Verimliliği</li>
                                <li>AR-GE Çalışmaları</li>
                                <li>Owner's Engineering</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
