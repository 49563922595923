import "./Footer.css"
import React, { useState } from "react"
import logo from "../images/calc_logo.svg"
import axios from "axios"

export default function Footer() {
    const [contactInfo, setContactInfo] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    })

    const [status, setStatus] = useState(null)

    function handleChange(event) {
        const { name, value } = event.target
        setContactInfo((prevOptData) => {
            return {
                ...prevOptData,
                [name]: value,
            }
        })
    }

    function onSubmitHandler(event) {
        event.preventDefault()
        sendEmail(contactInfo)
        setContactInfo({
            name: "",
            email: "",
            phone: "",
            message: "",
        })
    }

    async function sendEmail(info) {
        const url = "https://api.sendinblue.com/v3/smtp/email"
        const config = {
            headers: {
                accept: "application/json",
                "api-key":
                    "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",
                "content-type": "application/json",
            },
        }

        const data = {
            sender: {
                name: "Duka Teknoloji",
                email: "dukateknoloji@gmail.com",
            },
            to: [
                {
                    name: "Calculus Mühendislik",
                    email: "info@calculusmuhendislik.com",
                },
            ],

            subject: "Bize Ulaşın",
            htmlContent: `<html><head></head><body><p>Bize Ulaşından ulaşan kişi,</p>İsim: ${info.name}</p></p>Email: ${info.email}</p></p>Telefon: ${info.phone}</p></p>Mesaj: ${info.message}</p></body></html>`,
        }
        try {
            const result = await axios.post(url, data, config)
            setStatus(result.status)
        } catch (error) {
            setStatus(error.response.status)
        }
    }

    let statusRender

    if (status === null) {
        statusRender = ""
    } else if (status === 201) {
        statusRender = <p style={{ margin: 12 }}>Mail başarıyla gönderildi.</p>
    } else {
        statusRender = (
            <p style={{ margin: 12 }}>
                Mail gönderilemedi daha sonra tekrar deneyiniz.
            </p>
        )
    }

    return (
        <div className="footer-container">
            <div className="top-footer-container">
                <div className="footer-left-container">
                    <img
                        className="footer-logo"
                        src={logo}
                        alt="Calculus Mühendislik"
                    />
                    <p className="top-footer-text">
                        Gelişmelerden haberdar olmak için sosyal medya
                        hesaplarımızı takip edebilirsiniz.
                    </p>
                    <div className="footer-social-container">
                        <a
                            href="https://www.facebook.com/turkertan"
                            target="_blank"
                        >
                            <i className="fa-brands fa-facebook-f fa-lg footer-social"></i>
                        </a>
                        <a
                            href="https://www.instagram.com/ertann_turk/"
                            target="_blank"
                        >
                            <i className="fa-brands fa-instagram fa-xl footer-social"></i>
                        </a>

                        <a
                            href="https://www.linkedin.com/in/ertanturk/"
                            target="_blank"
                        >
                            <i className="fa-brands fa-linkedin fa-xl footer-social"></i>
                        </a>
                    </div>
                </div>
                {/* <div className="footer-right-container">
                    <div className="footer-list-container">
                        <p className="footer-list-header">Habeo</p>
                        <ul className="footer-list">
                            <li>Scaevola</li>
                            <li>Aliquam</li>
                            <li>Noluisse</li>
                            <li>Epicuri</li>
                            <li>Detracto</li>
                        </ul>
                    </div>
                    <div className="footer-list-container">
                        <p className="footer-list-header">Habeo</p>
                        <ul className="footer-list">
                            <li>Scaevola</li>
                            <li>Aliquam</li>
                            <li>Noluisse</li>
                            <li>Epicuri</li>
                            <li>Detracto</li>
                        </ul>
                    </div>
                    <div className="footer-list-container">
                        <p className="footer-list-header">Habeo</p>
                        <ul className="footer-list">
                            <li>Scaevola</li>
                            <li>Aliquam</li>
                            <li>Noluisse</li>
                            <li>Epicuri</li>
                            <li>Detracto</li>
                        </ul>
                    </div>
                </div> */}
                <form
                    onSubmit={onSubmitHandler}
                    className="contact-us-container"
                >
                    <p> Bize Ulaşın</p>
                    <input
                        type="text"
                        name="name"
                        value={contactInfo.name}
                        onChange={handleChange}
                        placeholder="İsim soyisim"
                        required
                    />
                    <input
                        type="email"
                        value={contactInfo.email}
                        onChange={handleChange}
                        name="email"
                        placeholder="Email"
                        size="30"
                        required
                    />
                    <input
                        type="tel"
                        name="phone"
                        value={contactInfo.phone}
                        onChange={handleChange}
                        placeholder="Telefon"
                        size="12"
                        required
                    />
                    <textarea
                        type="text"
                        name="message"
                        value={contactInfo.message}
                        onChange={handleChange}
                        placeholder="Mesajınız"
                        required
                    />
                    {statusRender}
                    <button>Gönder</button>
                </form>
            </div>
            <div className="bottom-footer-container">
                <p className="bottom-footer-text">
                    © Calculus Mühendislik Elektrik Makina İnşaat Taahhüt Sanayi
                    ve Ticaret Limited Şirketi © 2023.
                </p>
                <p className="bottom-footer-text">Tüm Hakları Saklıdır.</p>
            </div>
        </div>
    )
}
