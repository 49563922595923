export const gallery = [
    {
        thumbnail: require("../images/solar-panel.png"),
        name: "Güneş Enerji Santralleri",
        info: "Temiz ve yeşil enerji",
        slides: [
            require("../images/galleryImage/gunesPanel/1.jpeg"),
            require("../images/galleryImage/gunesPanel/2.jpeg"),
            require("../images/galleryImage/gunesPanel/3.jpeg"),
            require("../images/galleryImage/gunesPanel/4.jpeg"),
            require("../images/galleryImage/gunesPanel/5.jpeg"),
            require("../images/galleryImage/gunesPanel/6.jpeg"),
            require("../images/galleryImage/gunesPanel/7.jpeg"),
            require("../images/galleryImage/gunesPanel/8.jpeg"),
            require("../images/galleryImage/gunesPanel/9.jpeg"),
            require("../images/galleryImage/gunesPanel/10.jpeg"),
            require("../images/galleryImage/gunesPanel/11.jpeg"),
            require("../images/galleryImage/gunesPanel/12.jpeg"),
            require("../images/galleryImage/gunesPanel/13.jpeg"),
            require("../images/galleryImage/gunesPanel/14.jpeg"),
            require("../images/galleryImage/gunesPanel/16.jpeg"),
            require("../images/galleryImage/gunesPanel/17.jpeg"),
            require("../images/galleryImage/gunesPanel/18.jpeg"),
            require("../images/galleryImage/gunesPanel/19.jpeg"),
            require("../images/galleryImage/gunesPanel/20.jpeg"),
            require("../images/galleryImage/gunesPanel/21.jpeg"),
            require("../images/galleryImage/gunesPanel/22.jpeg"),
            require("../images/galleryImage/gunesPanel/23.jpeg"),
            require("../images/galleryImage/gunesPanel/24.jpeg"),
        ],
    },
    {
        thumbnail: require("../images/galleryImage/otomasyon.jpg"),
        name: "Otomasyon",
        info: "Endüstriyel otomasyon",
        slides: [require("../images/galleryImage/otomasyon/1.jpeg")],
    },
    {
        thumbnail: require("../images/galleryImage/otomasyon2.jpg"),
        name: "3D Tasarım",
        info: "3D Tasarım ve geliştirme",
        slides: [
            require("../images/galleryImage/3dresim/9.jpeg"),
            require("../images/galleryImage/3dresim/1.jpeg"),
            require("../images/galleryImage/3dresim/2.jpeg"),
            require("../images/galleryImage/3dresim/3.jpeg"),
            require("../images/galleryImage/3dresim/4.jpeg"),
            require("../images/galleryImage/3dresim/5.jpeg"),
            require("../images/galleryImage/3dresim/6.jpeg"),
            require("../images/galleryImage/3dresim/7.jpeg"),
            require("../images/galleryImage/3dresim/8.jpeg"),
            require("../images/galleryImage/3dresim/10.jpeg"),
        ],
    },
]
