export const slides = [
    {
        image: require("../images/solarpanels.png"),
        title: "Solar-Panel",
        header1: "Temiz ve yeşil bir dünya için",
        header2: "Güneş enerjisi santralleri",
        infoText:
            "Bir hayalle çıktığımız bu yolda dünya ve insanlık için kurduğumuz santrallerle 410 adet ağacın kesilmesine ve 15 ton karbondioksitin atmosfere salınmasına engel olduk.",
    },
    {
        image: require("../images/otomasyon2.jpg"),
        title: "otomasyon",
        header1: "TEKNOLOJİYİ YAKALAYIN",
        header2: "ENDÜSTRİYEL OTOMASYON",
        infoText:
            "Tüm endüstrilerde modern makine ve tesisler için en yüksek verimlilik, esneklik ve uygun maliyette çözümler sunuyoruz.",
    },

    {
        image: require("../images/tasarim.jpg"),
        title: "Çizim",
        header1: "FİKİRLERİNİZİ HAYATA GEÇİRİN",
        header2: "2D, 3D TASARIM VE TEKNİK ÇİZİM",
        infoText:
            "Firmaların özgün fikirleri doğrultusunda üretkenliğini arttırarak daha verimli endüstriyel tasarımlar yapılarak teknolojisi yüksek ürünlerin ortaya çıkmasına yardımcı oluyoruz",
    },
    {
        image: require("../images/elektrik.jpg"),
        title: "elektrik",
        header1: "ELEKTRİK PROJE VE TAAHHÜT",
        header2: "İŞİ UZMANINA BIRAKIN",
        infoText:
            "Jeotermal Santrallerden Güneş Enerji Sistemlerine kadar pek çok alandaki tecrübemizle elektrik proje ve taahhüt işlerini yapmaya devam ediyoruz.",
    },
]
