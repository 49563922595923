import "./GalleryImage.css"
import React from "react"
import Lightbox from "yet-another-react-lightbox"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/thumbnails.css"

export default function GalleryImage({ galleryData }) {
    const [open, setOpen] = React.useState(false)
    const thumbnailsRef = React.useRef(null)
    const slidesArr = []

    galleryData.slides.map((slide, i) =>
        slidesArr.push({ src: galleryData.slides[i] })
    )
    return (
        <>
            <div
                className="gallery-image-container"
                onClick={() => setOpen(true)}
            >
                <div className="gci">
                    <img
                        className="gallery-image"
                        src={galleryData.thumbnail}
                        alt=""
                    />
                    <div className="gallery-image-info-container">
                        <p className="header3 gallery-image-infos">
                            {galleryData.name}
                        </p>
                        <p className="info-text2 gallery-image-infos">
                            {galleryData.info}
                        </p>
                    </div>
                </div>
            </div>

            <Lightbox
                open={open}
                close={() => setOpen(false)}
                plugins={[Thumbnails]}
                thumbnails={{ ref: thumbnailsRef }}
                controller={{ closeOnBackdropClick: true }}
                // on={{
                //     click: () => {
                //         ;(thumbnailsRef.current?.visible
                //             ? thumbnailsRef.current?.hide
                //             : thumbnailsRef.current?.show)?.()
                //     },
                // }}
                slides={slidesArr}
            />
        </>
    )
}
