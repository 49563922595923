import "./ImageSlider.css"
import React, { useState, useEffect } from "react"

function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
}

export default function ImageSlider({ slides, forwardedRef }) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [windowSize, setWindowSize] = useState(getWindowSize())

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize())
        }

        window.addEventListener("resize", handleWindowResize)

        return () => {
            window.removeEventListener("resize", handleWindowResize)
        }
    }, [])
    let iconSize
    function goToPrevious() {
        const isFirstSlide = currentIndex === 0
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1

        setCurrentIndex(newIndex)
    }

    function goToNext() {
        const isLastSlide = currentIndex === slides.length - 1
        const newIndex = isLastSlide ? 0 : currentIndex + 1
        setCurrentIndex(newIndex)
    }

    // function goToSlide(slideIndex) {
    //     setCurrentIndex(slideIndex)
    // }
    if (windowSize.innerWidth < 425) {
        iconSize = "fa-2x"
    } else if (windowSize.innerWidth < 992) {
        iconSize = "fa-3x"
    } else {
        iconSize = "fa-5x"
    }

    useEffect(() => {
        let slider = setInterval(goToNext, 8000)
        return () => clearInterval(slider)
    }, [currentIndex])

    return (
        <div className="slider-container" ref={forwardedRef}>
            <div className="slider">
                <div className="arrow-back" onClick={goToPrevious}>
                    <i className={`fa-solid fa-angle-left ${iconSize}`}></i>
                    {/* <img src={arrowBack} alt="" /> */}
                </div>
                <div className="arrow-next" onClick={goToNext}>
                    <i className={`fa-solid fa-angle-right ${iconSize}`}></i>
                    {/* <img src={arrowNext} alt="" /> */}
                </div>
                <div className="section-center">
                    {slides.map((slide, slideIndex) => {
                        let position = "nextSlide"
                        if (
                            slideIndex === currentIndex + 1 ||
                            (currentIndex === slides.length - 1 &&
                                slideIndex === slides.length - slides.length)
                        ) {
                            return (
                                <article
                                    key={slideIndex}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundImage: `linear-gradient(
                                    90deg,
                                    #1b1b26 -2.05%,
                                    rgba(27, 27, 38, 0) 97.57%
                                ), url(${slide.image})`,
                                    }}
                                    className={position}
                                >
                                    <div className="text-container text-container-slider">
                                        <p className="slider-header2">
                                            {slide.header2}
                                        </p>
                                        <p className="slider-header1">
                                            {slide.header1}
                                        </p>
                                        {windowSize.innerWidth > 576 && (
                                            <p className="slider-info-text">
                                                {slide.infoText}
                                            </p>
                                        )}
                                    </div>
                                </article>
                            )
                        }
                        if (slideIndex === currentIndex) {
                            position = "activeSlide"
                            return (
                                <article
                                    key={slideIndex}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundImage: `linear-gradient(
                                    90deg,
                                    #1b1b26 -2.05%,
                                    rgba(27, 27, 38, 0) 97.57%
                                ), url(${slide.image})`,
                                    }}
                                    className={position}
                                >
                                    <div className="text-container text-container-slider">
                                        <p className="slider-header2">
                                            {slide.header2}
                                        </p>
                                        <p className="slider-header1">
                                            {slide.header1}
                                        </p>
                                        {windowSize.innerWidth > 575 && (
                                            <p className="slider-info-text">
                                                {slide.infoText}
                                            </p>
                                        )}
                                    </div>
                                </article>
                            )
                        }
                        if (
                            slideIndex === currentIndex - 1 ||
                            (currentIndex === 0 &&
                                slideIndex === slides.length - 1)
                        ) {
                            position = "lastSlide"
                            return (
                                <article
                                    key={slideIndex}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundImage: `linear-gradient(
                                    90deg,
                                    #1b1b26 -2.05%,
                                    rgba(27, 27, 38, 0) 97.57%
                                ), url(${slide.image})`,
                                    }}
                                    className={position}
                                >
                                    <div className="text-container text-container-slider">
                                        <p className="slider-header2">
                                            {slide.header2}
                                        </p>
                                        <p className="slider-header1">
                                            {slide.header1}
                                        </p>
                                        {windowSize.innerWidth > 576 && (
                                            <p className="slider-info-text">
                                                {slide.infoText}
                                            </p>
                                        )}
                                    </div>
                                </article>
                            )
                        }
                    })}
                </div>

                {/* <div>
                {slides.map((slide, slideIndex) => {
                    return (
                        <div
                            key={slideIndex}
                            onClick={goToSlide.bind(slideIndex)}
                        >
                            <i className="fa-regular fa-circle"></i>
                        </div>
                    )
                })}
            </div> */}
            </div>
        </div>
    )
}
