import "./CommentSection.css"
import React from "react"
import Comment from "./ui/Comment"
import Carousel from "./ui/Carousel"
import turkerler from "../images/turkerler.png"
import karizma from "../images/karizma_enerji.png"
import green from "../images/green_chemicals.png"
import limak from "../images/limak.png"

export default function CommentSection() {
    const items = [
        {
            image: turkerler,
            commentName: "John Doe",
            companyName: "Türkerler1",
            comment:
                " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta quam odit natus labore, beatae praesentium!",
        },
        {
            image: karizma,
            commentName: "John Doe",
            companyName: "Şirket ismi 2",
            comment:
                " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta quam odit natus labore, beatae praesentium!",
        },
        {
            image: green,
            commentName: "John Doe",
            companyName: "Şirket ismi 3",
            comment:
                " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta quam odit natus labore, beatae praesentium!",
        },
        {
            image: limak,
            commentName: "John Doe",
            companyName: "Şirket ismi 4",
            comment:
                " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta quam odit natus labore, beatae praesentium!",
        },
        {
            image: green,
            commentName: "John Doe",
            companyName: "Şirket ismi 3",
            comment:
                " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta quam odit natus labore, beatae praesentium!",
        },
        {
            image: limak,
            commentName: "John Doe",
            companyName: "Şirket ismi 4",
            comment:
                " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta quam odit natus labore, beatae praesentium!",
        },
        {
            image: green,
            commentName: "John Doe",
            companyName: "Şirket ismi 3",
            comment:
                " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta quam odit natus labore, beatae praesentium!",
        },
        {
            image: limak,
            commentName: "John Doe",
            companyName: "Şirket ismi 4",
            comment:
                " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta quam odit natus labore, beatae praesentium!",
        },
    ]
    return (
        <div>
            <div className="image-container">
                {/* <p className="white-header2">Slogan Başlığı</p> */}
                <p className="white-header1">Temiz ve Yeşil Bir Dünya için</p>
            </div>
            {/* <div className="comment-section-container">
                <div className="text-container">
                    <p className="header2">Müşterilerimiz</p>
                    <p className="header1">Yorumlar</p>
                </div> */}

            {/* <div className="comments">
                    <Comment
                        img={turkerler}
                        name={"John Doe"}
                        companyName={"Türkerler"}
                        comment={
                            " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta quam odit natus labore, beatae praesentium!"
                        }
                    />
                    <Comment
                        img={turkerler}
                        name={"John Doe"}
                        companyName={"Türkerler"}
                        comment={
                            " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta quam odit natus labore, beatae praesentium!"
                        }
                    />{" "}
                    <Comment
                        img={turkerler}
                        name={"John Doe"}
                        companyName={"Türkerler"}
                        comment={
                            " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta quam odit natus labore, beatae praesentium!"
                        }
                    />
                </div> */}

            {/* <Carousel items={items} />
            </div> */}
        </div>
    )
}
